import '@/css/app.css'
import Alpine from 'alpinejs'
import Headroom from 'headroom.js'
import collapse from '@alpinejs/collapse'
import { gsap } from 'gsap'
import Masonry from 'masonry-layout'

import barba from '@barba/core'

const headroom = () => {
    const headroom = new Headroom(document.querySelector('header'), {
        offset: 100,
        onTop: function () {
            document.querySelector('body').classList.add('headroom--top')
            document.querySelector('body').classList.remove('headroom--not-top')
        },
        onNotTop: function () {
            document.querySelector('body').classList.remove('headroom--top')
            document.querySelector('body').classList.add('headroom--not-top')
        }
    }).init()
}

// Initialize components
const initComponents = async (refresh = false) => {
    const components = [
        'fancybox',
        'swiper',
        'accordionComp',
        'langSwitcher',
        'googleMaps',
        'faq',
        'home-cols'
    ]

    const importPromises = []

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((comp) => {
                    importPromises.push(init(comp))
                })
            }
        )

        importPromises.push(importPromise)
    }

    importPromises.push(
        import('./base/animations.js').then(({ initAnimations }) =>
            initAnimations()
        )
    )

    if (!refresh) {
        importPromises.push(
            import('./base/menu.js').then(({ initMenu }) => initMenu()),
            import('./base/smooth-scroll').then(({ initSmoothScroll }) =>
                initSmoothScroll()
            )
        )
    }

    return await Promise.all(importPromises)
}

// Variable to store the clicked element
let clickedElement = null

const setActiveAnchor = () => {
    const anchors = document.querySelectorAll('a')

    // Add event listeners to menu items
    anchors.forEach((item) => {
        item.addEventListener('click', (event) => {
            clickedElement = event.currentTarget
        })
    })
}

const pageTransition = () => {
    const menuItems = document.querySelectorAll('#main-menu nav a'),
        anchors = document.querySelectorAll('a'),
        bgMenu = document.querySelector('#bg-menu')

    setActiveAnchor()
    barba.init({
        debug: true,
        transitions: [
            {
                name: 'fade-transition',

                async before(data) {
                    if (
                        data.trigger !== undefined &&
                        data.trigger !== 'back' &&
                        data.trigger !== 'forward'
                    ) {
                        bgMenu.classList.add('!visible')
                        if (
                            data.trigger.classList &&
                            !data.trigger.classList.contains('menu-item') &&
                            data.trigger.getAttribute('href') !== 'javascript:;'
                        ) {
                            return new Promise((resolve) => {
                                bgMenu.classList.add('will-redirect')
                                setTimeout(() => {
                                    bgMenu.classList.remove('will-redirect')

                                    setTimeout(() => {
                                        return resolve()
                                    }, 600)
                                }, 20)
                            })
                        }
                        document.documentElement.classList.add('loading')
                    }
                },

                // The enter hook runs when the new page has been loaded
                async enter(data) {
                    const request = data.request || data.next?.request
                    if (request && request.headers) {
                        request.headers.set('X-Barba', 'true')
                    }
                    setActiveAnchor()
                    masonry()
                    window.scrollTo(0, 0)

                    // Access the h1 element from the new page's container
                    const newHeading =
                        data.next.container.querySelector('h1.load-animation')

                    if (clickedElement) {
                        if (newHeading) {
                            const lastSpan = clickedElement.querySelector(
                                '&>span:last-of-type'
                            )

                            if (lastSpan) {
                                gsap.to(lastSpan, {
                                    fontSize:
                                        'calc(max(320 * var(--unit-fx), 125px))',
                                    paddingTop: '10vh',
                                    top: 0,
                                    y: 0,
                                    marginRight: 0,
                                    duration: 0.4,
                                    onStart: () => {
                                        gsap.to(
                                            lastSpan.querySelector('span'),
                                            {
                                                skewX: 0, // Skew to 0 at the start
                                                duration: 0.2 // Ensure a smooth transition
                                            }
                                        )

                                        // Create an array of menu items and filter out the clicked one
                                        menuItems.forEach((item) => {
                                            const firstSpan =
                                                item.querySelector(
                                                    '&>span:first-of-type'
                                                )

                                            if (firstSpan) {
                                                if (clickedElement !== item) {
                                                    gsap.to(item, {
                                                        autoAlpha: 0,
                                                        duration: 0.3
                                                    })
                                                }
                                            } else {
                                                gsap.to(item, {
                                                    autoAlpha: 0,
                                                    duration: 0.3
                                                })
                                            }
                                        })
                                    },
                                    onComplete: async () => {
                                        setTimeout(() => {
                                            // Reset styles after the menu is closed
                                            gsap.set(lastSpan, {
                                                fontSize: '',
                                                color: '',
                                                marginRight: '',
                                                top: '', // Reset to default
                                                bottom: '', // Reset to default
                                                y: () => '-100%' // Reset translation
                                            })

                                            // Apply the skew back to -8deg
                                            lastSpan.querySelector(
                                                'span'
                                            ).style.transform = ''

                                            // Reset styles for menu items
                                            menuItems.forEach((item) => {
                                                gsap.set(item, {
                                                    autoAlpha: 1
                                                })
                                                item.classList.remove('active')
                                            })

                                            clickedElement.classList.add(
                                                'active'
                                            )
                                        }, 600)
                                    }
                                })
                            }
                        } else {
                            setTimeout(() => {
                                // Reset styles for menu items
                                menuItems.forEach((item) => {
                                    item.classList.remove('active')
                                })

                                clickedElement.classList.add('active')
                            }, 600)
                        }
                    }

                    const menuStore = Alpine.store('menu')

                    if (data.trigger !== 'back' && data.trigger !== 'forward') {
                        bgMenu.classList.add('redirected')
                    }

                    if (menuStore) {
                        menuStore.close()
                    }

                    setTimeout(() => {
                        document.documentElement.classList.remove('loading')
                    }, 300)

                    await initComponents(true)

                    setTimeout(async () => {
                        bgMenu.classList.remove(
                            'redirected',
                            'will-redirect',
                            '!visible'
                        )
                    }, 1000)
                }
            }
        ]
    })
}

const masonry = () => {
    const masonryElements = document.querySelectorAll('.masonry');

    masonryElements.forEach(el => {
        // Use imagesLoaded for images and manually handle videos
        const videos = el.querySelectorAll('video');
        let loadedVideosCount = 0;

        // Check if there are any videos, and wait for them to load
        if (videos.length > 0) {
            videos.forEach(video => {
                // Wait for each video to fully load and play before initializing Masonry
                video.addEventListener('loadeddata', () => {
                    loadedVideosCount++;
                    // Once all videos are loaded, initialize Masonry
                    if (loadedVideosCount === videos.length) {
                        initializeMasonry(el);
                    }
                });
            });
        } else {
            // If no videos, just initialize Masonry after images are loaded
            initializeMasonry(el);
        }

        setTimeout(() => initializeMasonry(el), 300);
    });
};

const initializeMasonry = (el) => {
    new Masonry(el); // Initialize Masonry for each element
};



// App main
const main = async () => {
    await initComponents() // Initialize components on the first load

    masonry();
    pageTransition()
    headroom()
}

main().then(() => {
    window.Alpine = Alpine

    Alpine.plugin(collapse)
    Alpine.start()
})
